import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import ReactHtmlParser from 'react-html-parser';
import Prism from 'prismjs';
import {
  Breadcrumb,
  Grid,
  Header,
  Icon,
  Item,
} from 'semantic-ui-react';
import BlogPostTeaser from './BlogPostTeaser';
import Layout from '../../components/layout';
import { SEO } from '../../components/seo';

import DrupalLoginButton from '../drupal-oauth/DrupalLoginButton';

import './BlogPostTemplate.less';

require('prismjs/themes/prism.css');

/**
 * Override this to provide the wrapping page layout for a blog post.
 */
const BlogPostTemplate = props => {
  const {
    title,
    created,
    author,
    body,
    bodyImages,
    summary,
    images,
    timeToComplete,
    previousPost,
    nextPost,
  } = props;

  useEffect(() => {
    Prism.highlightAll();
  });

  const image =
    images[0] && images[0].relationships
      ? images[0].relationships.imageFile.localFile.childImageSharp.fluid.src
      : false;

  let postBody = <div dangerouslySetInnerHTML={{ __html: body }} />
  if (bodyImages) {
    postBody = new ReactHtmlParser(body, {
      transform: function transform(node) {
        if (
          node.type === 'tag' &&
          node.name === 'article' &&
          node.attribs['data-media-source'] === 'image'
        ) {
          const imageData = bodyImages.find(
            el =>
              el.drupal_internal__fid ===
              parseInt(node.attribs['data-media-source-value'])
          )
          if (imageData) {
            return <Img fluid={imageData.localFile.childImageSharp.fluid} />
          }
        }
      },
    })
  }

  return (
    <Layout>
      <SEO
        title={title}
        description={summary}
        image={image}
        meta={[{ name: 'og:type', content: 'article' }]}
      >
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              "headline": "${title}",
              "publisher": "Hey Node",
              "datePublished": "${created}",
              "articleBody": ${JSON.stringify(body)},
              "author" : {
                "@type": "Person",
                "name": "${author}"
              }
            }
          `}
        </script>
      </SEO>
      <Grid columns="equal" stackable>
        <Grid.Row>
          <Grid.Column></Grid.Column>
        </Grid.Row>
        {images[0] && (
          <Grid.Row>
            <Grid.Column>
              <Img
                fluid={
                  images[0].relationships.imageFile.localFile.childImageSharp
                    .fluid
                }
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column className="main-column">
            <article className="blog-post">
              <Breadcrumb>
                <Breadcrumb.Section as={Link} to="/blog" content="Blog" />
                <Breadcrumb.Divider icon="right angle" />
              </Breadcrumb>
              <Header as="h1">{title}</Header>
              {postBody}
            </article>
          </Grid.Column>
          <Grid.Column className="left-column" width={2}>
            <div className="meta">
              By {author}
              <ul>
                <li>{created}</li>
                {timeToComplete && <li>{timeToComplete} min read</li>}
              </ul>
            </div>
            <p className="cta">
              If you enjoyed this post{' '}
              <Link to="/signup">sign up for our newsletter</Link> to get
              notified of new content.
            </p>
          </Grid.Column>
          <Grid.Column className="right-column" textAlign="center" width={2}>
            <p>
              <strong>Learn Node.js</strong>
              <br />
              Access all our written and video Node.js tutorials.
            </p>
            <DrupalLoginButton
               title={
                 <>
                   <Icon name="right arrow" />
                   Sign up
                 </>
               }
               useRegistrationLink
               classes="ui icon primary left labeled button signup"
             />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="blog-navigation">
          <Grid.Column>
            <Item.Group>
              {previousPost && (
                <>
                  <Header as="h3" floated="left">
                    &larr; Previous post
                  </Header>
                  <BlogPostTeaser {...previousPost} />
                </>
              )}
            </Item.Group>
          </Grid.Column>
          <Grid.Column>
            <Item.Group>
              {nextPost && (
                <>
                  <Header as="h3" floated="right">
                    Next post &rarr;
                  </Header>
                  <BlogPostTeaser {...nextPost} />
                </>
              )}
            </Item.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  timeToComplete: PropTypes.number,
  images: PropTypes.array,
  bodyImages: PropTypes.array,
  previousPost: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      timeToComplete: PropTypes.number,
    }),
  ]),
  nextPost: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      timeToComplete: PropTypes.number,
    }),
  ]),
};

BlogPostTemplate.defaultProps = {
  timeToComplete: null,
  previousPost: false,
  nextPost: false,
  images: [],
  bodyImages: null,
};

export default BlogPostTemplate;
